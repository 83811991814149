import React, { useEffect } from "react";
import axios from "axios";

function UpdateBd() {
  // useEffect(() => {
  //   axios.post(`https://back.r3mob.fr/brain`).then((res) => {
  //   });
  // }, []);

  // useEffect(() => {
  // }, []);

  return <div>UpdateBd</div>;
}

export default UpdateBd;
