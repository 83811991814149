import React, { useEffect } from "react";
import axios from "axios";

function ClearBd() {
  // useEffect(() => {
  //   axios.post(`https://back.r3mob.fr/brain/clear`).then((res) => {
  //   });
  // }, []);
  return <div>ClearBd</div>;
}

export default ClearBd;
