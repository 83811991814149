import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { faEnvelope, faPhone, faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Link } from 'react-router-dom';

import {
    CardContainer,
    InfoContainer,
    Icon,
    Infos,
    InfoRow
} from '../styles/CartePerso';

const defaultImage = 'default_user.png';

const Name = styled.h1`
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    @media (max-width: 1625px) {
    font-size: 1rem;
  }
`;
const PageLink = styled(Link)`
  text-decoration: none; 
  color: inherit; 
`;
const NameUnderCard= styled.p`
    font-size: 1.2rem;
    font-weight: bold;
    color: black;
    @media (max-width: 1625px) {
    font-size: 0.8rem;
    }
`;

const CartePerso = ({ id, name, email, phone, address }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [chercheur, setChercheur] = useState([]);
    const [imageLoaded, setImageLoaded] = useState(false);
	useEffect(() => {
        // Annuler les requêtes AJAX précédentes si elles existent
        const source = axios.CancelToken.source();
    
        axios
          .get(`https://back.r3mob.fr/detailsChercheur/image/${id}`, {
            cancelToken: source.token,
          })
          .then((res) => {
            if (res.data !== null) {
              setChercheur(res.data);
              setImageLoaded(true);
            } else {
              window.location.replace('/404');
            }
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              // La requête a été annulée
              console.log('Requête annulée:', error.message);
            } else {
              // Une erreur s'est produite pendant la requête
              console.error('Erreur de requête:', error.message);
            }
          });
    
        // Annuler la requête lorsqu'un nouveau rendu se produit
        return () => source.cancel();
      }, [id]);
    const backgroundImage = chercheur.imageData
    ? `url(data:image/png;base64,${chercheur.imageData})`
      : `url(${defaultImage})`;
  
    return (
        <>
          <PageLink to={`/chercheur/${id}`}>
            <CardContainer
              style={{
                backgroundImage,
                filter: imageLoaded ? 'none' : 'blur(5px)',
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {isHovered && (
                <InfoContainer>
                  <Name>{name}</Name>
                  <InfoRow>
                    <Icon>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </Icon>
                    <Infos>{email}</Infos>
                  </InfoRow>
                  {/* <InfoRow>
                    <Icon>
                      <FontAwesomeIcon icon={faPhone} />
                    </Icon>
                    <Infos>{phone}</Infos>
                  </InfoRow> */}
                  <InfoRow>
                    <Icon>
                      <FontAwesomeIcon icon={faMapMarker} />
                    </Icon>
                    <Infos>{address}</Infos>
                  </InfoRow>
                </InfoContainer>
              )}
            </CardContainer>
          </PageLink>
          <NameUnderCard>{name}</NameUnderCard>
        </>
      );
};

export default CartePerso;
